// gatsby
import { Link } from 'gatsby'
// react
import React, { createRef, useState, useEffect } from 'react'
import axios from 'axios'
// react bootstrap components
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Row from 'react-bootstrap/Row'
import Pagination from 'react-bootstrap/Pagination'
import Popover from 'react-bootstrap/Popover'
import Table from 'react-bootstrap/Table'
import Tooltip from 'react-bootstrap/Tooltip'
// external modules
import { v4 as uuidv4 } from 'uuid'
// ui
import 'react-day-picker/lib/style.css'
import 'bootstrap/dist/css/bootstrap.css'
import { CgNotes } from 'react-icons/cg'
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'
import { FcDownload, FcSynchronize } from 'react-icons/fc'
import DayPickerInput from 'react-day-picker/DayPickerInput'
// internal components
import DepletionStationOptions from 'components/options/station/depletion'
import DepletionUnitOptions from 'components/options/unit/depletion'
import { GlobalDispatchContext } from 'context/global_context_provider'
import Layout from 'components/layout'
// internal functions
import {
  customDate,
  compareJsDateToDbDate
} from 'tools/date'
import { splitArray, getMcoModelUnavailibleText } from 'tools/util'
import {
  compareUnit,
  compareCycle,
  compareOwner,
  compareTimestamp,
  compareName,
  compareNotes,
  compareString,
  compareStation
} from 'tools/sort'

function compareDate (a, b) {
  return (
    (parseFloat(a.timestamp.N) > parseFloat(b.timestamp.N))
      ? 1
      : -1
  )
}

const PredictionsIndexPage = (props) => {
  const dispatch = React.useContext(GlobalDispatchContext)
  useEffect(() => {
    props.fetch()
  }, [])
  const [station, setStation] = useState('')
  const [m2u, setM2u] = useState(false)
  const toggleM2u = (e) => setM2u(!m2u)
  const [t1, setT1] = useState('')
  const [t2, setT2] = useState('')
  const [owner, setOwner] = useState('')
  const [day, setDay] = useState('')
  const [unit, setUnit] = useState('')
  const [cycle, setCycle] = useState('')
  const [scenario, setScenario] = useState('')
  const [name, setName] = useState('')
  const [notes, setNotes] = useState('')
  const [sortConfig, setSortConfig] = React.useState('')
  const cases = (
    (props.data)
      ? props.data.predictions
      : null
  )
  const fullName = (
    (props.data)
      ? props.data.profile.familyName +
        ', ' +
        props.data.profile.givenName
      : ''
  )
  const sorted = React.useMemo(() => {
    let sorte = null
    if (!cases) {
      return sorte
    } else if (!sortConfig) {
      sorte = (
        cases
          .sort((a, b) => compareTimestamp(a, b, false))
          .sort(compareName)
          .sort(compareCycle)
          .sort(compareUnit)
          .sort(compareStation)
          .sort(compareOwner)
      )
    } else {
      const compareFunctions = {
        station: compareStation,
        unit: compareUnit,
        cycle: compareCycle,
        owner: compareOwner,
        timestamp: compareTimestamp,
        name: compareName,
        scenario: compareString
      }
      console.log('compare functions')
      console.log(compareFunctions)
      const compareFunction = compareFunctions[sortConfig[0]]
      sorte = (
        cases
          .sort((a, b) => compareTimestamp(a, b, false))
          .sort(compareName)
          .sort(compareCycle)
          .sort(compareUnit)
          .sort(compareStation)
          .sort((a, b) => compareFunction(a, b, sortConfig[1]))
      )
    }
    if (owner) {
      sorte = sorte.filter(item => (
        item.owner.S.toLowerCase().includes(owner.toLowerCase())
      ))
    }
    if (day) {
      sorte = sorte.filter(item => (
        compareJsDateToDbDate(day, item.date.M)
      ))
    }
    if (station) {
      sorte = sorte.filter(item => item.station.S === station)
    }
    if (unit) {
      sorte = sorte.filter(item => item.unit.N === unit)
    }
    if (cycle) {
      sorte = sorte.filter(item => item.cycle.N === cycle)
    }
    if (scenario) {
      sorte = sorte.filter(item => item.scenario.S === scenario)
    }
    if (name) {
      sorte = sorte.filter(item => item.name.S.includes(name))
    }
    if (notes) {
      sorte = sorte.filter(item => item.notes.S.includes(notes))
    }
    if (props.personalView && props.data) {
      sorte = sorte.filter(item => (item.owner.S === fullName))
    }
    return sorte
  }, [
    cases,
    sortConfig,
    owner,
    day,
    station,
    unit,
    cycle,
    scenario,
    name,
    notes,
    props.personalView,
    props.data
  ])

  const [selection, setSelection] = useState(null)
  const getSelectedCaseIds = () => (
    sorted
      .filter((x, i) => selection[i])
      .map(x => x.id.S)
  )
  const getSelectedCases = () => (
    sorted
      .filter((x, i) => selection[i])
  )
  const toggleSelection = (index) => {
    const copy = [...selection]
    copy[index] = !copy[index]
    setSelection(copy)
  }
  const toggleSelectionWithChecks = i => {
    const selectionsNow = (
      selection
        .map((x, i) => [x, i])
        .filter((x, i) => x[0])
        .map(x => x[1])
    )
    console.log(selectionsNow)
    if (selectionsNow.length > 3) {
      dispatch({
        type: 'addAlert',
        toAdd: {
          id: uuidv4(),
          type: 'warningText',
          text: 'application error detected, please ' +
            'contact administrator (selectionsNow.length>3)'
        }
      })
    } else if (selectionsNow.length === 3 && !selection[i]) {
    } else if (
      selectionsNow.length > 0 &&
      sorted[selectionsNow[0]].station.S !== sorted[i].station.S
    ) {
      dispatch({
        type: 'addAlert',
        toAdd: {
          id: uuidv4(),
          type: 'text',
          text: 'Please select (up to 3) depletions for the same station, unit'
        }
      })
    } else if (
      selectionsNow.length > 0 &&
      sorted[selectionsNow[0]].unit.N !== sorted[i].unit.N
    ) {
      dispatch({
        type: 'addAlert',
        toAdd: {
          id: uuidv4(),
          type: 'text',
          text: 'Please select (up to 3) depletions for the same station, unit'
        }
      })
    } else if (
      selectionsNow.length > 0 &&
      sorted[selectionsNow[0]].scenario.S !== sorted[i].scenario.S
    ) {
      dispatch({
        type: 'addAlert',
        toAdd: {
          id: uuidv4(),
          type: 'text',
          text: 'Please select (up to 3) depletions for the same scenario'
        }
      })
    } else {
      if (selectionsNow.length === 0) {
        const selectedUnit = sorted[i].unit.N
        const selectedStation = sorted[i].station.S
        setT2(
          props.appInfo
            .stations[selectedStation]
            .units[selectedUnit]
            .limit
        )
      }
      toggleSelection(i)
    }
  }
  useEffect(() => {
    if (sorted) {
      setSelection(sorted.map(x => false))
    }
  }, [sorted])
  const unselect = () => {
    setSelection([...selection].map(x => false))
  }
  const requestSort = key => {
    unselect()
    if (key === sortConfig[0] && !sortConfig[1]) {
      setSortConfig('')
    } else {
      const ascending = (
        !sortConfig ||
        sortConfig[0] !== key ||
        (sortConfig[0] === key && !sortConfig[1])
      )
      setSortConfig([key, ascending])
    }
  }
  const [page, setPage] = useState(0)
  const nPages = (
    (sorted)
      ? Math.ceil(sorted.length / 20)
      : null
  )
  useEffect(() => {
    document.onkeydown = (e) => {
      if (e.key === 'ArrowRight' && nPages && page < nPages - 1) {
        setPage(page + 1)
      } else if (e.key === 'ArrowLeft' && nPages && page > 0) {
        setPage(page - 1)
      }
    }
  })
  const thisPagination = (
    (sorted && sorted.length > 20)
      ? (
        <Pagination>
          {
            [...Array(nPages).keys()].map(i => (
              <Pagination.Item
                key={'page-item-' + i}
                active={(i === page)}
                onClick={() => setPage(i)}
              >
                {i + 1}
              </Pagination.Item>
            ))
          }
        </Pagination>
        )
      : null
  )
  const downloadUrl = (
    'https://api.demo.bwnuclear.ai/download/mco/predictions/'
  )
  const tableBody = (
    (cases && sorted.length > 0 && selection)
      ? (
          splitArray(sorted, 20)[page]
            .map((item, index) => (
              <tr key={'pred-' + index}>
                <td>
                  <Form.Check
                    checked={selection[page * 20 + index]}
                    onChange={() => (
                      toggleSelectionWithChecks(page * 20 + index))
                    }
                  />
                </td>
                <td>{item.name.S}</td>
                <td>{item.owner.S}</td>
                <td>{props.appInfo.stations[item.station.S].name}</td>
                <td className='text-center'>{item.unit.N}</td>
                <td className='text-center'>{item.cycle.N}</td>
                <td>
                  {
                    (item.scenario.S === 'design')
                      ? 'Design'
                      : 'Operating'
                  }
                </td>
                <td>
                  {(item.batch_size.N >= 0)
                    ? item.batch_size.N
                    : 'N/A'}
                </td>
                <td>{customDate(item.date.M)}</td>
                <td>
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    overlay={
                      <Tooltip id='button-tooltip-2'>
                        {
                          (item.notes)
                            ? (item.notes.S !== '')
                                ? item.notes.S
                                : 'none'
                            : 'none'
                        }
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <Container
                        {...triggerHandler}
                        ref={ref}
                        className='p-0'
                      >
                        <Row xs='auto' className='justify-content-center'>
                          <Col xs='auto' className='p-0'>
                            {
                              (item.notes)
                                ? (item.notes.S !== '')
                                    ? <CgNotes />
                                    : null
                                : null
                            }
                          </Col>
                        </Row>
                      </Container>
                    )}
                  </OverlayTrigger>
                </td>
                <td>
                  <Container className='justify-content-center p-0'>
                    <Row xs='auto' className='justify-content-center'>
                      <Col xs='auto' className='p-0'>
                        <a
                          href={downloadUrl + item.id.S}
                        >
                          <FcDownload />
                        </a>
                      </Col>
                    </Row>
                  </Container>
                </td>
              </tr>
            ))
        )
      : <></>
  )
  const submitReport = async () => {
    const cases = getSelectedCaseIds()
    console.log(cases)
    const nSelected = cases.length
    let modelUnavailible = null
    if (nSelected > 0) {
      const index = (
        selection
          .map((x, i) => [x, i])
          .filter((x, i) => x[0])
          .map(x => x[1])[0]
      )
      const item = sorted[index]
      modelUnavailible = getMcoModelUnavailibleText(
        item.station.S,
        item.unit.N
      )
    }
    if (nSelected === 0) {
      console.log('You must select a case to build a report')
      dispatch({
        type: 'addAlert',
        toAdd: {
          id: uuidv4(),
          type: 'warningText',
          text: 'You must select a case to build a report'
        }
      })
    } else if (nSelected > 3) {
      console.log('You may only build reports with 3 cases')
      dispatch({
        type: 'addAlert',
        toAdd: {
          id: uuidv4(),
          type: 'warningText',
          text: 'You can build reports with a max of 3 cases'
        }
      })
    } else if (t2 === '') {
      dispatch({
        type: 'addAlert',
        toAdd: {
          id: uuidv4(),
          type: 'warningText',
          text: 'You must select an FDPS threshold'
        }
      })
    } else if (modelUnavailible) {
      dispatch({
        type: 'addAlert',
        toAdd: {
          id: uuidv4(),
          type: 'warningText',
          text: modelUnavailible
        }
      })
    } else {
      const url = ('https://api.demo.bwnuclear.ai/buildreport/mco')
      const params = {
        params: {
          t1: t1,
          t2: t2,
          cases: cases.slice(0, 3),
          m2u: m2u,
          username: props.data.profile.username,
          first_name: props.data.profile.givenName,
          last_name: props.data.profile.familyName,
          email: props.data.profile.email
        },
        withCredentials: true
      }
      console.log(params)
      const reportResult = await axios.get(url, params)
      console.log(reportResult)
      if (reportResult.data.response) {
        dispatch({
          type: 'addAlert',
          toAdd: {
            id: uuidv4(),
            type: 'text',
            text: 'Report started, will appear in the Report Archive table shortly'
          }
        })
      } else {
        dispatch({
          type: 'addAlert',
          toAdd: { id: uuidv4(), type: 'renew' }
        })
      }
    }
  }

  const deleteProjections = async () => {
    const files = getSelectedCaseIds()
    unselect()
    console.log(files)
    const url = ('https://api.demo.bwnuclear.ai/delete/mco/prediction')
    if (files.length > 3) {
      console.log('Delete files only 3 at a time')
      dispatch({
        type: 'addAlert',
        toAdd: {
          id: uuidv4(),
          type: 'warningText',
          text: 'please delete files one at a time'
        }
      })
    } else if (files.length === 0) {
      dispatch({
        type: 'addAlert',
        toAdd: {
          id: uuidv4(),
          type: 'warningText',
          text: 'No file selected'
        }
      })
    } else {
      const selectedCases = getSelectedCases()
      for (let i = 0; i < selectedCases.length; i++) {
        if (selectedCases[i].owner.S !== fullName) {
          dispatch({
            type: 'addAlert',
            toAdd: {
              id: uuidv4(),
              type: 'warningText',
              text: 'Only delete files that you own'
            }
          })
          return
        }
      }
      const params = { params: { id: files.join(',') }, withCredentials: true }
      console.log(params)
      const deleteResult = await axios.get(url, params)
      console.log(deleteResult)
      console.log(deleteResult)
      if (deleteResult.data.status) {
        dispatch({
          type: 'addAlert',
          toAdd: {
            id: uuidv4(),
            type: 'text',
            text: 'Delete completed'
          }
        })
        props.fetch()
      } else {
        dispatch({
          type: 'addAlert',
          toAdd: { id: uuidv4(), type: 'renew' }
        })
      }
    }
  }
  const stations = (props.data) ? props.data.app_info.stations : null
  const sortButton = key => {
    if (sortConfig[0] !== key) {
      return (
        <FaSort
          type='button'
          onClick={() => requestSort(key)}
        />
      )
    } else if (sortConfig[1]) {
      return (
        <FaSortUp
          type='button'
          onClick={() => requestSort(key)}
        />
      )
    } else {
      return (
        <FaSortDown
          type='button'
          onClick={() => requestSort(key)}
        />
      )
    }
  }
  return (
    <>
      <Container>
        <Row className='mb-4'>
          <h1
            className='featurette-heading'
            style={{ fontWeight: '100' }}
          >
            View Depletions
          </h1>
        </Row>
        <>
          <Card
            style={{
              fontWeight: '100',
              backgroundColor: '#caccd1',
              borderRadius: '5px',
              boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'
            }}
            className='lead mb-3'

          >
            <Card.Header as='lead'>
              Filter Table Entries
            </Card.Header>
            <Card.Body>
              <Form className='justify-content-between'>
                <Row className='mb-3'>
                  <Col xs='4'>
                    <Form.Group>
                      <Form.Label> Name Contains </Form.Label>
                      <Form.Control
                        type='text'
                        size='md'
                        placeholder=''
                        value={name}
                        onChange={e => setName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs='4'>
                    {
                      (props.personalView)
                        ? (
                          <Form.Group>
                            <Form.Label>Owner</Form.Label>
                            <Form.Control
                              type='text'
                              size='md'
                              value={fullName}
                              disabled
                            />
                          </Form.Group>
                          )
                        : (
                          <Form.Group>
                            <Form.Label>Owner</Form.Label>
                            <Form.Control
                              type='text'
                              size='md'
                              value={owner}
                              onChange={(e) => {
                                setOwner(e.target.value)
                              }}
                              placeholder='LAST, FIRST'
                            />
                          </Form.Group>
                          )
                    }
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col xs='auto'>
                    <Form.Group>
                      <Form.Label> Station </Form.Label>
                      <Form.Select
                        size='md'
                        value={station}
                        onChange={e => {
                          setUnit('')
                          setStation(e.target.value)
                        }}
                      >
                        <option value=''> </option>
                        <DepletionStationOptions />
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs='auto'>
                    <Form.Group>
                      <Form.Label> Unit </Form.Label>
                      <Form.Select
                        size='md'
                        value={unit}
                        onChange={e => setUnit(e.target.value)}
                      >
                        {
                          (station)
                            ? <option value=''> </option>
                            : null
                        }
                        <DepletionUnitOptions station={station} />
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col xs='auto'>
                    <Form.Group>
                      <Form.Label> Cycle </Form.Label>
                      <Form.Control
                        size='md'
                        type='number'
                        min='0'
                        max='1000'
                        onChange={e => setCycle(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs='auto'>
                    <Form.Group>
                      <Form.Label>Scenario</Form.Label>
                      <Form.Select
                        size='md'
                        value={scenario}
                        onChange={e => setScenario(e.target.value)}
                      >
                        <option value=''>--</option>
                        <option value='design'>Design</option>
                        <option value='operating'>Operating</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col xs='4'>
                    <Form.Group>
                      <Form.Label> Notes Contain </Form.Label>
                      <Form.Control
                        type='text'
                        size='md'
                        placeholder=''
                        value={notes}
                        onChange={e => setNotes(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs='4'>
                    <Form.Group>
                      <Form.Label>Date</Form.Label>

                      <div>
                        <DayPickerInput
                          onDayChange={(day, mods, input) => {
                            setDay(day)
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col xs='auto'>
                    <Form.Switch
                      type='switch'
                      label='Personal View'
                      title='Toggle between Personal and Company View'
                      checked={props.personalView}
                      onChange={props.togglePersonalView}
                    />
                  </Col>
                  <br /><br />
                  <Row className='mb-3'>
                    <Col xs='10'>
                      <Button
                        size='sm'
                        type='button'
                        title='Refresh Data'
                        variant='outline-primary'
                        style={{ borderRadius: '25px' }}
                        onClick={() => {
                          unselect()
                          props.fetch()
                        }}
                      >
                        <FcSynchronize size={24} />
                        &nbsp;
                        Refresh Data
                      </Button>
                    </Col>
                  </Row>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </>
        <Row className='mb-3'>
          <Col>
            <Table striped bordered hover style={{ boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)' }}>
              <thead>
                <tr>
                  <th> </th>
                  <th>Depletion Name {sortButton('name')}</th>
                  <th>Owner {sortButton('owner')}</th>
                  <th>Station {sortButton('station')}</th>
                  <th className='text-center'>Unit {sortButton('unit')}</th>
                  <th className='text-center'>Cycle {sortButton('cycle')}</th>
                  <th>Scenario {sortButton('scenario')}</th>
                  <th>
                    Batch Size
                  </th>
                  <th>
                    Upload (EST)
                    {sortButton('timestamp')}
                  </th>
                  <th>
                    <Container className='justify-content-center p-0'>
                      <Row xs='auto' className='justify-content-center'>
                        <Col xs='auto'>
                          Notes
                        </Col>
                      </Row>
                    </Container>
                  </th>
                  <th>
                    <Container className='justify-content-center p-0'>
                      <Row xs='auto' className='justify-content-center'>
                        <Col xs='auto'>
                          File
                        </Col>
                      </Row>
                    </Container>
                  </th>
                </tr>
              </thead>
              <tbody>{tableBody}</tbody>
            </Table>
          </Col>
        </Row>
        <Row xs='auto' className='justify-content-center'>
          <Col>
            {thisPagination}
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs='auto'>
            <Form.Group>
              <Form.Label>Threshold 1 (%) </Form.Label>
              <Form.Control
                className='justify-content-center'
                size='sm'
                type='number'
                min='0.01'
                step='0.01'
                value={t1}
                onChange={e => setT1(e.target.value)}
              />
              <small className='fw-light text-muted'>Warning Limit (Optional)</small>
            </Form.Group>
          </Col>
          <Col xs='auto'>
            <Form.Group>
              <Form.Label>Threshold 2 (%) </Form.Label>
              <Form.Control
                className='justify-content-center'
                size='sm'
                type='number'
                min='0.01'
                step='0.01'
                value={t2}
                onChange={e => setT2(e.target.value)}
              />
              <small className='fw-light text-muted'>FDPS Limit</small>
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-3'>
          <Col xs='auto'>
            <Form.Switch
              type='switch'
              label='Email to User'
              value={m2u}
              onChange={toggleM2u}
            />
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col>
            <Dropdown drop='up'>
              <Dropdown.Toggle variant='secondary' id='dd-action'>
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={submitReport}>
                  Generate MCO Projection
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={deleteProjections}
                >
                  Delete
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to='/mco/predictions/add'
                    style={{ textDecoration: 'none' }}
                  >
                    Add New Depletion
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>
    </>
  )
}

PredictionsIndexPage.Layout = Layout

export default PredictionsIndexPage
